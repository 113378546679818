import React from "react";

import ArrowLeftMediumWhite from "../../../../Assets/img/arrow-left-medium-white.png";
import Line from "../../../../Assets/img/section-one-line.png";

import { HomeHandler } from "../Handler/HandlerImpl";
import { Trans, useTranslation } from "react-i18next";

type props = {
  handler: HomeHandler;
};

const SectionOne = ({ handler }: props) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    let index = handler.indexStep;
    const intervalId = setInterval(() => {
      index = (index + 1) % 5;

      switch (index) {
        case 0:
          handler.changeStep("one");
          break;
        case 1:
          handler.changeStep("two");
          break;
        case 2:
          handler.changeStep("three");
          break;
        case 3:
          handler.changeStep("four");
          break;
        case 4:
          handler.changeStep("five");
          break;
      }

      if (index === 5) index = 0;
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [handler.step]);

  return (
    <div className="home-section-one">
      <div className="step">
        <div className="title">
          <Trans i18nKey={"home.section.one.title"}></Trans>
        </div>
        <div className="progress">
          <div
            className={handler.step.one ? `element active` : "element"}
            onClick={() => handler.changeStep("one")}
          >
            {handler.step.one ? (
              <>
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.one")}
                </p>
                <img
                  style={{ marginRight: 24 }}
                  src={ArrowLeftMediumWhite}
                  alt=""
                />
              </>
            ) : (
              <>
                <img src={Line} alt="" />
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.one")}
                </p>
              </>
            )}
          </div>
          <div
            className={handler.step.two ? `element active` : "element"}
            onClick={() => handler.changeStep("two")}
          >
            {handler.step.two ? (
              <>
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.two")}
                </p>
                <img
                  style={{ marginRight: 24 }}
                  src={ArrowLeftMediumWhite}
                  alt=""
                />
              </>
            ) : (
              <>
                <img src={Line} alt="" />
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.two")}
                </p>
              </>
            )}
          </div>
          <div
            className={handler.step.three ? `element active` : "element"}
            onClick={() => handler.changeStep("three")}
          >
            {handler.step.three ? (
              <>
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.three")}
                </p>
                <img
                  style={{ marginRight: 24 }}
                  src={ArrowLeftMediumWhite}
                  alt=""
                />
              </>
            ) : (
              <>
                <img src={Line} alt="" />
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.three")}
                </p>
              </>
            )}
          </div>
          <div
            className={handler.step.four ? `element active` : "element"}
            onClick={() => handler.changeStep("four")}
          >
            {handler.step.four ? (
              <>
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.four")}
                </p>
                <img
                  style={{ marginRight: 24 }}
                  src={ArrowLeftMediumWhite}
                  alt=""
                />
              </>
            ) : (
              <>
                <img src={Line} alt="" />
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.four")}
                </p>
              </>
            )}
          </div>
          <div
            className={handler.step.five ? `element active` : "element"}
            onClick={() => handler.changeStep("five")}
          >
            {handler.step.five ? (
              <>
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.five")}
                </p>
                <img
                  style={{ marginRight: 24 }}
                  src={ArrowLeftMediumWhite}
                  alt=""
                />
              </>
            ) : (
              <>
                <img src={Line} alt="" />
                <p style={{ marginLeft: 24 }}>
                  {t("home.section.one.progress.five")}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="image">
        <img src={handler.image} alt="" />
      </div>
    </div>
  );
};

export default SectionOne;
