import React from "react";

// CSS
import "../../../../Assets/css/software/three.css";

// Image
import PersonImage from "../../../../Assets/img/software-person-05.png";
import { useTranslation } from "react-i18next";

const SectionThree = () => {
  const { t } = useTranslation();

  return (
    <div className="software-section-three">
      <div className="section-three-ct">
        <div className="section-three-title">
          <div className="tt">{t("software.section.three.title.text")}</div>
          <div className="desc">
            {t("software.section.three.title.description")}
          </div>
        </div>
        <div className="three-list">
          <div className="three-item">
            <h2>01</h2>
            <div>{t("software.section.three.title.item.one")}</div>
          </div>
          <div className="three-item">
            <h2>02</h2>
            <div>{t("software.section.three.title.item.two")}</div>
          </div>
          <div className="three-item">
            <h2>03</h2>
            <div>{t("software.section.three.title.item.three")}</div>
          </div>
          <div className="three-item">
            <h2>04</h2>
            <div>{t("software.section.three.title.item.four")}</div>
          </div>
        </div>
        <div className="section-three-content">
          <div className="tt-image">
            <img src={PersonImage} alt="" />
          </div>
          <div className="tt-content">
            <div className="tt-nd">
              {t("software.section.three.title.item.one")}
            </div>
            <div className="tt-nd">
              {t("software.section.three.title.item.two")}
            </div>
            <div className="tt-nd">
              {t("software.section.three.title.item.three")}
            </div>
            <div className="tt-nd" style={{ borderBottom: "none" }}>
              {t("software.section.three.title.item.four")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
