import React from "react";
import MainUI from "./Presenter/View/UI";
import HomeHandler from "./Presenter/Handler/Handler";

const Home = () => {
  const handler = HomeHandler();

  return <MainUI handler={handler} />;
};

export default Home;
