import { createSlice } from "@reduxjs/toolkit";

const LANGUAGE_LOCAL_STORAGE_KEY = "language";

export enum LANGUAGE_CODE {
  JA = "ja",
  EN = "en",
  VI = "vi"
}

export function getLanguageFromLocalStorage() {
  const lang = (localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) as LANGUAGE_CODE) ?? LANGUAGE_CODE.JA;

  return lang;
}

function setLanguageToLocalStorage(lang: LANGUAGE_CODE) {
  localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, lang);
}

export type LanguageSliceType = {
  language: LANGUAGE_CODE;
};

export const initialState: LanguageSliceType = {
  language: getLanguageFromLocalStorage(),
};

export const LanguageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      setLanguageToLocalStorage(action.payload);
    },
  },
});

export const { setLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
