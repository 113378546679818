import React from "react";
import HeaderComponent from "../../../../Components/Header/Header";

// CSS
import "../../../../Assets/css/policy/policy.css";
import FooterComponent from "../../../../Components/Footer/Footer";

import { PolicyHandler } from "../Handler/HandlerImpl";

import PolicyImage from "../../../../Assets/img/policy-image-01.png";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import MenuItem from "./MenuItem";
import { useDispatch } from "react-redux";
import { setOpenMenu } from "../../../../Components/Header/Slice/Slice";
import { useTranslation } from "react-i18next";

type props = {
  handler: PolicyHandler;
};

const MainUI = ({ handler }: props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      dispatch(setOpenMenu(false));
    };
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <div className="policy-header">
        <HeaderComponent />
      </div>
      <div className="policy-container">
        <div className="policy-content">
          <div className="policy-title">{t("policy.title")}</div>
          <div className="policy-nd">
            <div className="policy-sidebar">
              <MenuItem
                itemName={t("policy.section.one.title")}
                active={handler.active.one}
                separate="one"
                blockName="block-one"
                top={-50}
                handler={handler}
              />
              <MenuItem
                itemName={t("policy.section.two.title")}
                active={handler.active.two}
                separate="two"
                blockName="block-two"
                top={-50}
                handler={handler}
              />
              <MenuItem
                itemName={t("policy.section.three.title")}
                active={handler.active.three}
                separate="three"
                blockName="block-three"
                top={-50}
                handler={handler}
              />
              <MenuItem
                itemName={t("policy.section.four.title")}
                active={handler.active.four}
                separate="four"
                blockName="block-four"
                top={-50}
                handler={handler}
              />
            </div>
            <div className="policy-desc">
              <div className="policy-image">
                <img src={PolicyImage} alt="" />
              </div>
              <SectionOne />
              <SectionTwo />
              <SectionThree />
              <SectionFour />
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default MainUI;
