import React from "react";
import SectionHeader from "./SectionHeader";
import SectionOne from "./SectionOne";
import FooterComponent from "../../../../Components/Footer/Footer";
import SectionTwo from "./SectionTwo";

import SectionThree from "./SectionThree";
import { SoftwareHandler } from "../Handler/HandlerImpl";
import LanguageComponent from "../../../../Components/Language/Language";
import { useDispatch } from "react-redux";
import { setOpenMenu } from "../../../../Components/Header/Slice/Slice";

type props = {
	handler: SoftwareHandler;
};

const MainUI = ({ handler }: props) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });

		return () => {
			dispatch(setOpenMenu(false));
		};
	}, []);

	return (
		<>
			<SectionHeader />
			<SectionOne handler={handler} />
			<SectionTwo handler={handler} />
			<LanguageComponent /> 
			<SectionThree />
			<FooterComponent />
		</>
	);
};

export default MainUI;
