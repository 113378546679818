import { useTranslation } from "react-i18next";

const SectionFour = () => {
  const { t } = useTranslation();

  return (
    <div
      className="policy-content-element"
      style={{ marginTop: 100 }}
      id="block-four"
    >
      <div className="policy-tt">{t("policy.section.four.title")}</div>
      <div className="policy-nd-element">
        <div className="item c4-text">
          {t("policy.section.four.content.line.one")}
        </div>
        <div className="item c4-text">
          {t("policy.section.four.content.line.two")} <br />
          {t("policy.section.four.content.line.three")} <br />
          {t("policy.section.four.content.line.four")} <br />
          {t("policy.section.four.content.line.five")} <br />
          {t("policy.section.four.content.line.six")}
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
