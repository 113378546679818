import React from "react";

import HeaderComponent from "../../../../Components/Header/Header";

import ArrowRightMediumDefault from "../../../../Assets/img/arrow-right-medium-default.png";
import ArrowRightMediumActive from "../../../../Assets/img/arrow-right-medium-active.png";
import { HomeHandler } from "../Handler/HandlerImpl";

import IMBG01 from "../../../../Assets/img/home-bg-1.png";
import IMBG02 from "../../../../Assets/img/home-bg-02.png";
import IMBG03 from "../../../../Assets/img/home-bg-03.png";
import { Trans, useTranslation } from "react-i18next";

type props = {
  handler: HomeHandler;
};

const SectionHeader = ({ handler }: props) => {
  React.useEffect(() => {
    let index = handler.indexSlider;
    const intervalId = setInterval(() => {
      index = (index + 1) % 3;

      switch (index) {
        case 0:
          handler.changeStepSlider("one");
          break;
        case 1:
          handler.changeStepSlider("two");
          break;
        case 2:
          handler.changeStepSlider("three");
          break;
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [handler.slider]);

  const { t } = useTranslation();

  return (
    <div className={`home-header ${handler.classHeader}`}>
      <img src={IMBG01} style={{ display: "none" }} alt="" />
      <img src={IMBG02} style={{ display: "none" }} alt="" />
      <img src={IMBG03} style={{ display: "none" }} alt="" />
      <HeaderComponent />
      <div className="header-content">
        <div className="parent-description">
          <div className="description">
            Exceed your <br /> expectations
          </div>
          <div className="sub-description">
            {t("home.section.header.description")}
          </div>
        </div>
        {/* <div className="read-more">
                    Read more <img src={ArrowRightSmall} alt="" />
                </div> */}
        <div className="banner-slider">
          <div
            onClick={() => handler.changeStepSlider("one")}
            className={
              handler.slider.one
                ? "item border-top-active"
                : "item border-top-default"
            }
          >
            <span className={handler.slider.one ? "active-index" : ""}>#1</span>
            <img
              src={
                handler.slider.one
                  ? ArrowRightMediumActive
                  : ArrowRightMediumDefault
              }
              alt=""
            />
          </div>
          <div
            onClick={() => handler.changeStepSlider("two")}
            className={
              handler.slider.two
                ? "item border-top-active"
                : "item border-top-default"
            }
          >
            <span className={handler.slider.two ? "active-index" : ""}>#2</span>
            <img
              src={
                handler.slider.two
                  ? ArrowRightMediumActive
                  : ArrowRightMediumDefault
              }
              alt=""
            />
          </div>
          <div
            onClick={() => handler.changeStepSlider("three")}
            className={
              handler.slider.three
                ? "item border-top-active"
                : "item border-top-default"
            }
          >
            <span className={handler.slider.three ? "active-index" : ""}>
              #3
            </span>
            <img
              src={
                handler.slider.three
                  ? ArrowRightMediumActive
                  : ArrowRightMediumDefault
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeader;
