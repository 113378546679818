import { useState } from "react";
import { PolicyActiveEntity } from "../../Entity/Entity";

const DEFAULT_VALUE: PolicyActiveEntity = {
    one: false,
    two: false,
    three: false,
    four: false,
};

const PolicyHandler = () => {
    const [active, setActive] = useState<PolicyActiveEntity>({
        ...DEFAULT_VALUE,
        one: true,
    });

    const changeActive = (key: string) => {
        switch (key) {
            case "one":
                setActive((prev: PolicyActiveEntity) => {
                    return { ...DEFAULT_VALUE, one: true };
                });
                break;
            case "two":
                setActive((prev: PolicyActiveEntity) => {
                    return { ...DEFAULT_VALUE, two: true };
                });
                break;
            case "three":
                setActive((prev: PolicyActiveEntity) => {
                    return { ...DEFAULT_VALUE, three: true };
                });
                break;
            case "four":
                setActive((prev: PolicyActiveEntity) => {
                    return { ...DEFAULT_VALUE, four: true };
                });
                break;
        }
    };

    return {
        active,

        changeActive,
    };
};

export default PolicyHandler;
