import { configureStore } from "@reduxjs/toolkit";

// Reducer

import HeaderReducer from "../Components/Header/Slice/Slice";
import LanguageReducer from '../Redux/Language';

const store = configureStore({
  reducer: {
    header: HeaderReducer,
    language: LanguageReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
