import React from "react";

// CSS
import "../../../../Assets/css/talent/four.css";

import TalentImage01 from "../../../../Assets/img/talent-section-four-image-01.png";
import TalentImage02 from "../../../../Assets/img/talent-section-four-image-02.png";
import TalentImage03 from "../../../../Assets/img/talent-section-four-image-03.png";
import ArrowLeft from "../../../../Assets/img/talent-section-four-arrow-left.png";
import ArrowDown from "../../../../Assets/img/talent-section-four-arrow-down.png";
import { TalentHandler } from "../Handler/HandlerImpl";
import { useTranslation } from "react-i18next";

type props = {
  handler: TalentHandler;
};

const SectionFour = ({ handler }: props) => {
  const { t } = useTranslation();

  return (
    <div className="talent-section-four">
      <div className="talent-content">
        <div className="talent-title">
          <div className="head">{t("talent.section.four.title")}</div>
          <div className="desc">{t("talent.section.four.description")}</div>
        </div>
        <div className="talent-collapse">
          <div className="item">
            <div className="title" onClick={() => handler.setupCollapseOne()}>
              <div className="image">
                <img src={TalentImage01} alt="" />
              </div>
              <span>{t("talent.section.four.item.one.title")}</span>
              <div className="image">
                <img src={handler.collapseOne ? ArrowDown : ArrowLeft} alt="" />
              </div>
            </div>
            {handler.collapseOne ? (
              <>
                <div className="desc">
                  {t("talent.section.four.item.one.description")}
                </div>
              </>
            ) : null}
          </div>
          <div className="item">
            <div className="title" onClick={() => handler.setupCollapseTwo()}>
              <img src={TalentImage02} alt="" />
              <span>{t("talent.section.four.item.two.title")}</span>
              <img src={handler.collapseTwo ? ArrowDown : ArrowLeft} alt="" />
            </div>
            {handler.collapseTwo ? (
              <>
                <div className="desc">
                  {t("talent.section.four.item.two.description")}
                </div>
              </>
            ) : null}
          </div>
          <div className="item border-bottom">
            <div className="title" onClick={() => handler.setupCollapseThree()}>
              <img src={TalentImage03} alt="" />
              <span>{t("talent.section.four.item.three.title")}</span>
              <img src={handler.collapseThree ? ArrowDown : ArrowLeft} alt="" />
            </div>
            {handler.collapseThree ? (
              <>
                <div className="desc">
                  {t("talent.section.four.item.three.description")}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
