import { Form } from "antd";
import { EstimateServiceImpl } from "../../Usecase/ServiceImpl";
import { useState } from "react";
import SuccessNotification from "../../../../Common/Notification";
import { FormEntity } from "../../Entity/Entity";
import { useTranslation } from "react-i18next";

const EstimateHandler = (service: EstimateServiceImpl) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = async (values: any) => {
    const data: FormEntity = {
      companyName: values?.companyName ?? "",
      fullName: values?.fullName ?? "",
      emailAddress: values?.emailAddress ?? "",
      telephoneNumber: values?.telephoneNumber ?? "",
      option: values?.option ?? "",
      contentOfInquiry: values?.contentOfInquiry ?? "",
    };

    setLoading(true);

    try {
      const response = await service.submitEstimateContent(data);
      if (response.status === 200) {
        SuccessNotification(t("notification.message.success"));
        setLoading(false);
      }

      // Form reset
      form.resetFields();
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    form,
    loading,

    handleSubmit,
  };
};

export default EstimateHandler;
