import React from "react";

// CSS
import "../../../../Assets/css/talent/three.css";

import TalentSectionThreeImage from "../../../../Assets/img/talent-section-three-image.png";
import { useTranslation } from "react-i18next";

const SectionThree = () => {
  const { t } = useTranslation();

  return (
    <div className="talent-section-three">
      <div className="talent-content">
        <div className="title">
          <div className="head">{t("talent.section.three.title")}</div>
          <div className="desc">
            {t(
              "talent.section.three.description"
            )}
          </div>
        </div>
        <div className="image">
          <img src={TalentSectionThreeImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
