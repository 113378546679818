import React from "react";

// CSS
import "../../../../Assets/css/software/one.css";

// Image
import SoftwareImage01 from "../../../../Assets/img/software-image-01.png";
import SoftwareAnalysis from "../../../../Assets/img/software-analysis.png";
import SoftwarePrototype from "../../../../Assets/img/software-prototype.png";
import SoftwareDevelopment from "../../../../Assets/img/software-development.png";
import SoftwareOperation from "../../../../Assets/img/software-operation.png";
import { SoftwareHandler } from "../Handler/HandlerImpl";
import { Trans, useTranslation } from "react-i18next";

type props = {
  handler: SoftwareHandler;
};

const SectionOne = ({ handler }: props) => {
  const { t } = useTranslation();

  return (
    <div className="software-section-one">
      <div className="title">
        <div className="s-head">
          <div className="desc">{t("software.section.one.desc")}</div>
          <div className="quote">{t("software.section.one.quote")}</div>
        </div>
        <div className="s-image-main">
          <img src={SoftwareImage01} alt="" />
        </div>
        <div className="s-content">
          <div className="head">
            <div className="st">{t("software.section.one.head.title")}</div>
            <div className="sd">
              {t("software.section.one.head.description")}
            </div>
          </div>
          <div className="s-block">
            <div className="s-item">
              <div>
                <img src={SoftwareAnalysis} alt="" />
              </div>
              <div className="s-desc">
                {t("software.section.one.item.one.title")}
              </div>
              <div className="s-card-content">
                <Trans i18nKey="software.section.one.item.one.description">
                  <span className="s-card-content-line"></span>
                </Trans>
              </div>
            </div>
            <div className="s-item">
              <div>
                <img src={SoftwarePrototype} alt="" />
              </div>
              <div className="s-desc">
                {t("software.section.one.item.two.title")}
              </div>
              <div className="s-card-content">
                {t("software.section.one.item.two.description")}
              </div>
            </div>
            <div className="s-item">
              <div>
                <img src={SoftwareDevelopment} alt="" />
              </div>
              <div className="s-desc">
                {t("software.section.one.item.three.title")}
              </div>
              <div className="s-card-content">
                <Trans i18nKey="software.section.one.item.three.description">
                  <span className="s-card-content-line"></span>
                </Trans>
              </div>
            </div>
            <div className="s-item">
              <div>
                <img src={SoftwareOperation} alt="" />
              </div>
              <div className="s-desc">
                {t("software.section.one.item.four.title")}
              </div>
              <div className="s-card-content">
                {t("software.section.one.item.four.description")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
