import React from "react";
import { useTranslation } from "react-i18next";

const SectionOne = () => {
  const { t } = useTranslation();

  return (
    <div className="policy-content-element" id="block-one">
      <div className="policy-tt">{t("policy.section.one.title")}</div>
      <div className="policy-nd-element">
        <div className="item">
          <div className="item-left heading item-heading-left">
            {t("policy.section.one.personal-info-type")}
          </div>
          <div className="item-right heading item-heading-right">
            {t("policy.section.one.personal-info-purpose")}
          </div>
        </div>
        <div className="item">
          <div className="item-left">
            {t("policy.section.one.business-partner-one.type")}
          </div>
          <div className="item-right">
            {t("policy.section.one.business-partner-one.purpose")}
          </div>
        </div>
        <div className="item bg-tripple">
          <div className="item-left">
            {t("policy.section.one.business-partner-two.type")}
          </div>
          <div className="item-right">
            {t("policy.section.one.business-partner-two.purpose")}
          </div>
        </div>
        <div className="item">
          <div className="item-left">
            {t("policy.section.one.business-partner-three.type")}
          </div>
          <div className="item-right">
            {t("policy.section.one.business-partner-three.purpose")}
          </div>
        </div>
        <div className="item bg-tripple">
          <div className="item-left">
            {t("policy.section.one.vietnamese-personel.type")}
          </div>
          <div className="item-right">
            {t("policy.section.one.vietnamese-personel.purpose")}
          </div>
        </div>
        <div className="item">
          <div className="item-left">
            {t("policy.section.one.employee.type")}
          </div>
          <div className="item-right">
            {t("policy.section.one.employee.purpose")}
          </div>
        </div>
        <div className="item bg-tripple">
          <div className="item-left">
            {t("policy.section.one.job-applicant.type")}
          </div>
          <div className="item-right">
            {t("policy.section.one.job-applicant.purpose")}
          </div>
        </div>
        <div className="item">
          <div className="item-left">
            {t("policy.section.one.inquiry.type")}
          </div>
          <div className="item-right">
            {t("policy.section.one.inquiry.purpose")}
          </div>
        </div>
        <div className="item bg-tripple">
          <div className="item-left">
            {t("policy.section.one.group-company.type")}
          </div>
          <div className="item-right">
            {t("policy.section.one.group-company.purpose")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
