import React from "react";

// CSS
import "../../../../Assets/css/software/two.css";

// Image
import SoftwareTeam from "../../../../Assets/img/software-team.png";
import { SoftwareHandler } from "../Handler/HandlerImpl";
import IconMinus from "../../../../Assets/icon/Minus.svg";
import IconPlus from "../../../../Assets/icon/Plus.svg";
import { useTranslation } from "react-i18next";

type props = {
  handler: SoftwareHandler;
};

const SectionTwo = ({ handler }: props) => {
  const { t } = useTranslation();

  return (
    <div className="software-section-two">
      <div className="section-two-content">
        <div className="section-two-title">
          <div className="section-two-ct">
            <div className="section-two-cap">
              {t("software.section.two.caption")}
            </div>
            <div>
              <img src={SoftwareTeam} alt="" />
            </div>
          </div>
          <div className="section-two-desc">
            {t("software.section.two.description")}
          </div>
        </div>
        <div className="software-scroll">
          <div className="section-two-row">
            {handler.slider.one ? (
              <>
                <div className="section-two-item activate section-two-item-show-01">
                  <div className="section-two-sm-ct">
                    Engineer{" "}
                    {handler.isMobile && (
                      <div
                        className="icon-minus"
                        onClick={() => handler.changeStepSlider("")}
                      >
                        <img src={IconMinus} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.one.line.one")}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.one.line.two")}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.one.line.three")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="section-two-item section-two-item-hide-01"
                  onMouseEnter={() => handler.changeStepSlider("one")}
                >
                  <h2>Engineer</h2>
                  {handler.isMobile && (
                    <div className="icon-plus">
                      <img src={IconPlus} alt="" />
                    </div>
                  )}
                </div>
              </>
            )}
            {handler.slider.two ? (
              <>
                <div className="section-two-item activate section-two-item-show-02">
                  <div className="section-two-sm-ct">
                    UI/UX Designer{" "}
                    {handler.isMobile && (
                      <div
                        className="icon-minus"
                        onClick={() => handler.changeStepSlider("")}
                      >
                        <img src={IconMinus} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.two.line.one")}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.two.line.two")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="section-two-item  section-two-item-hide-02"
                  onMouseEnter={() => handler.changeStepSlider("two")}
                >
                  <h2>UI/UX Designer</h2>
                  {handler.isMobile && (
                    <div className="icon-plus">
                      <img src={IconPlus} alt="" />
                    </div>
                  )}
                </div>
              </>
            )}
            {handler.slider.three ? (
              <>
                <div className="section-two-item activate section-two-item-show-03">
                  <div className="section-two-sm-ct">
                    Product Owner{" "}
                    {handler.isMobile && (
                      <div
                        className="icon-minus"
                        onClick={() => handler.changeStepSlider("")}
                      >
                        <img src={IconMinus} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.three.line.one")}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.three.line.two")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="section-two-item section-two-item-hide-03"
                  onMouseEnter={() => handler.changeStepSlider("three")}
                >
                  <h2>Product Owner</h2>
                  {handler.isMobile && (
                    <div className="icon-plus">
                      <img src={IconPlus} alt="" />
                    </div>
                  )}
                </div>
              </>
            )}
            {handler.slider.four ? (
              <>
                <div className="section-two-item activate section-two-item-show-04">
                  <div className="section-two-sm-ct">
                    IT Consultant{" "}
                    {handler.isMobile && (
                      <div
                        className="icon-minus"
                        onClick={() => handler.changeStepSlider("")}
                      >
                        <img src={IconMinus} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.four.line.one")}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.four.line.two")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="section-two-item section-two-item-hide-04"
                  onMouseEnter={() => handler.changeStepSlider("four")}
                >
                  <h2>IT Consultant</h2>
                  {handler.isMobile && (
                    <div className="icon-plus">
                      <img src={IconPlus} alt="" />
                    </div>
                  )}
                </div>
              </>
            )}
            {handler.slider.five ? (
              <>
                <div className="section-two-item activate section-two-item-show-05">
                  <div className="section-two-sm-ct">
                    Tester{" "}
                    {handler.isMobile && (
                      <div
                        className="icon-minus"
                        onClick={() => handler.changeStepSlider("")}
                      >
                        <img src={IconMinus} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.five.line.one")}
                  </div>
                  <div className="section-two-md-ct">
                    {t("software.section.two.item.five.line.two")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="section-two-item section-two-item-hide-05"
                  onMouseEnter={() => handler.changeStepSlider("five")}
                >
                  <h2>Tester</h2>
                  {handler.isMobile && (
                    <div className="icon-plus">
                      <img src={IconPlus} alt="" />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
