import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
const SuccessNotification = (message: string, description?: string) => {
    const placement: NotificationPlacement = "topRight";
    notification.success({
        duration: 2,
        message: message,
        description: description,
        placement,
        style: {
            borderTop: "2px solid #0FA048",
        },
    });
};

export default SuccessNotification;
