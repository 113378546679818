import { Form } from "antd";
import { useState } from "react";
import {
  SANAN_DOCUMENT_BROCHURE,
  SANAN_DOCUMENT_CASESTUDY,
} from "../../../../Common/Constant";
import SuccessNotification from "../../../../Common/Notification";
import { FormEntity } from "../../Entity/Entity";
import { DocumentServiceImpl } from "../../Usecase/ServiceImpl";
import { useTranslation } from "react-i18next";

const DocumentHandler = (service: DocumentServiceImpl) => {
  const [activeButtonOne, setActiveButtonOne] = useState<boolean>(true);
  const [activeButtonTwo, setActiveButtonTwo] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const changeButtonOne = () => {
    setActiveButtonOne((prev) => !prev);
  };

  const changeButtonTwo = () => {
    setActiveButtonTwo((prev) => !prev);
  };

  const { t } = useTranslation();

  const handleSubmit = async (values: any) => {
    const data: FormEntity = {
      companyName: values?.companyName ?? "",
      fullName: values?.fullName ?? "",
      emailAddress: values?.emailAddress ?? "",
      telephoneNumber: values?.telephoneNumber ?? "",
      option: values?.option ?? "",
      profileCompanyLink: activeButtonOne ? SANAN_DOCUMENT_BROCHURE : "",
      developmentResultLink: activeButtonTwo ? SANAN_DOCUMENT_CASESTUDY : "",
      contentOfInquiry: values?.contentOfInquiry ?? "",
    };

    setLoading(true);

    try {
      const response = await service.submitDocument(data);
      if (response.status === 200) {
        SuccessNotification(t("notification.message.success"));
        setLoading(false);
      }

      // Form reset
      form.resetFields();
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    form,
    loading,
    activeButtonOne,
    activeButtonTwo,

    changeButtonOne,
    changeButtonTwo,
    handleSubmit,
  };
};

export default DocumentHandler;
