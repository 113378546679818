import React from "react";

import PolicyArrowRight from "../../../../Assets/img/policy-arrow-right.png";
import { PolicyHandler } from "../Handler/HandlerImpl";

type MenuProps = {
    itemName: string;
    active: boolean;
    separate: string;
    blockName: string;
    top: number;
    handler: PolicyHandler;
};

const MenuItem = ({
    itemName,
    active,
    separate,
    blockName,
    top,
    handler,
}: MenuProps) => {
    const [anchorTarget, setAnchorTarget] = React.useState<HTMLElement>();

    React.useEffect(() => {
        setAnchorTarget(document.getElementById(blockName) ?? undefined);
    }, [blockName]);

    const handleClick = (event: any) => {
        event.preventDefault();
        if (anchorTarget) {
            const y =
                anchorTarget.getBoundingClientRect().top +
                window.pageYOffset +
                top;
            window.scrollTo({ top: y, behavior: "smooth" });
        }
    };

    return (
        <div
            className="policy-item"
            onClick={(e: any) => {
                handleClick(e);
                handler.changeActive(separate);
            }}
        >
            {active ? <img src={PolicyArrowRight} alt="" /> : null}

            <div className={`policy-head ${active ? "policy-active" : ""}`}>
                {itemName}
            </div>
        </div>
    );
};

export default MenuItem;
