import React, { useMemo } from "react";

// CSS
import "../../Assets/css/footer.css";

// logo
import copyright from "../../Assets/img/Copyright.png";
import logo from "../../Assets/img/footer-logo.svg";
import facebook from "../../Assets/img/facebook.svg";
import twitter from "../../Assets/img/twitter.png";
import pmarkEN01 from "../../Assets/img/17004872_01_EN.png";
import pmarkJP01 from "../../Assets/img/17004872_01_jp.png";
import linkedin from "../../Assets/img/linkedin.svg";
import ContactComponent from "./Contact";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SANANCORP_SITE_URL,
  SANAN_CONNECT_FACEBOOK_URL,
  SANAN_CONNECT_LINKEDIN_URL,
  SANAN_CONNECT_TWITTER_URL,
} from "../../Common/Constant";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { LANGUAGE_CODE } from "../../Redux/Language";

const FooterComponent = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const location = useLocation();
  const canShowContact = useMemo(() => {
    if (!location) return true;

    if (!location.pathname) return true;

    if (
      location.pathname !== "/estimate-contact" &&
      location.pathname !== "/document-download"
    ) {
      return true;
    }

    return false;
  }, [location]);

  const toCorpSite = () => {
    window.open(SANANCORP_SITE_URL, "_blank");
  };

  const toFacebookSite = () => {
    window.open(SANAN_CONNECT_FACEBOOK_URL, "_blank");
  };

  const toLinkedinSite = () => {
    window.open(SANAN_CONNECT_LINKEDIN_URL, "_blank");
  };

  const toTwitterSite = () => {
    window.open(SANAN_CONNECT_TWITTER_URL, "_blank");
  };

  const language = useSelector((state: RootState) => state.language.language);
  return (
    <>
      <div className="footer-wrapper">
        {canShowContact && <ContactComponent />}
        <div className="footer-content">
          <div className="logo">
            <img
              className="sanan clickable"
              src={logo}
              alt="logo"
              onClick={toCorpSite}
            />
            <div className="social">
              <div className="clickable" onClick={toFacebookSite}>
                <img src={facebook} alt="logo" />
              </div>
              <div className="clickable" onClick={toLinkedinSite}>
                <img src={linkedin} alt="logo" />
              </div>
              <div
                className="clickable"
                style={{ marginTop: "2px" }}
                onClick={toTwitterSite}
              >
                <img src={twitter} alt="logo" />
              </div>
            </div>
            {language === LANGUAGE_CODE.JA ? (
              <img className="pmark" src={pmarkJP01} alt="pmark" />
            ) : (
              <img className="pmark" src={pmarkEN01} alt="pmark" />
            )}
          </div>
          <div className="info">
            <div className="footer-row">
              <div className="footer-col">
                <p className="title">{t("footer.info.title")}</p>
                <p
                  className="item-navigate can-click"
                  onClick={() => navigate("/about")}
                >
                  {t("footer.about.title")}
                </p>
                {/* <p className="item-navigate">Blogs</p> */}
                <p
                  className="item-navigate can-click"
                  onClick={() => navigate("/policy")}
                >
                  {t("footer.policy.title")}
                </p>
                <p
                  className="item-navigate can-click"
                  onClick={() => navigate("/privacy")}
                >
                  {t("footer.privacy.title")}
                </p>
              </div>
              <div className="footer-col">
                <p className="title">{t("footer.service.title")}</p>
                <p
                  className="item-navigate can-click"
                  onClick={() => navigate("/software")}
                >
                  Software Development
                </p>
                <p
                  className="item-navigate can-click"
                  onClick={() => navigate("/talent")}
                >
                  Talent Resources Service
                </p>
              </div>
            </div>
            <div className="footer-row">
              <div className="footer-col">
                <p className="title">{t("footer.contact.title")}</p>
                <p className="item-navigate mail">{t("footer.contact.mail")}</p>
                <p className="item-navigate tel-section">
                  <div className="tel-title">{t("footer.tel.title")}</div>
                  <div className="tel-content">
                    <div>{t("footer.tel.content")}</div>
                  </div>
                </p>
              </div>
              <div className="footer-col">
                <p className="title">{t("footer.company.name.title")}</p>
                <p className="item-navigate bold">
                  {t("footer.company.name.content")}
                </p>
                <p className="item-navigate bold" style={{ marginTop: "8px" }}>
                  {t("footer.japanese-company.name.title")}
                </p>
                <p className="item-navigate">
                  {t("footer.japanese-company.name.content")}
                </p>

                <p className="title">{t("footer.company.address.title")}</p>
                <p className="item-navigate bold">
                  {t("footer.japapanse-company.address.content.one")}
                </p>
                <p className="item-navigate">
                  {t("footer.japapanse-company.address.content.two")}
                </p>
                <br />
                <p className="item-navigate bold">
                  {t("footer.hanoi-company.address.content.one")}
                </p>
                <p className="item-navigate">
                  {t("footer.hanoi-company.address.content.two")}
                </p>
                <br />
                <p className="item-navigate bold">
                  {t("footer.thanh-hoa-company.address.content.one")}
                </p>
                <p className="item-navigate">
                  {t("footer.thanh-hoa-company.address.content.two")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <span>{t("footer.copyright.title")}</span>
          <img src={copyright} alt="" />
          <span>{t("footer.copyright.content")}</span>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;
