import React from "react";
import HeaderComponent from "../../../../Components/Header/Header";

// CSS
import "../../../../Assets/css/about/one.css";
import FooterComponent from "../../../../Components/Footer/Footer";

// Image
import IconSanAn from "../../../../Assets/icon/SanAn.svg";
import AboutImageMediumIcon from "../../../../Assets/img/about-medium-icon.png";
import { AboutHandler } from "../Handler/HandlerImpl";

import AboutSectionOneImage01 from "../../../../Assets/img/about-section-one-image-01.png";
import AboutSectionOneImage02 from "../../../../Assets/img/about-section-one-image-02.png";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import MenuItem from "./ItemMenu";
import { useDispatch } from "react-redux";
import { setOpenMenu } from "../../../../Components/Header/Slice/Slice";
import { Trans, useTranslation } from "react-i18next";

type props = {
  handler: AboutHandler;
};

const MainUI = ({ handler }: props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      dispatch(setOpenMenu(false));
    };
  }, []);

  return (
    <>
      <div className="about-container">
        <HeaderComponent />
        <div className="about-section-one">
          <div className="section-one-title">
            <div className="head">{t("about.section.one.title")}</div>
          </div>
          <div className="section-one-content">
            <div className="sidebar">
              <MenuItem
                active={handler.step.one}
                itemName={t("about.section.one.sidebar.one")}
                separate="one"
                blockName="block-one"
                top={-50}
                handler={handler}
              />
              <MenuItem
                active={handler.step.two}
                itemName={t("about.section.one.sidebar.two")}
                separate="two"
                blockName="block-two"
                top={-50}
                handler={handler}
              />
              <MenuItem
                active={handler.step.three}
                itemName={t("about.section.one.sidebar.three")}
                separate="three"
                blockName="block-three"
                top={-50}
                handler={handler}
              />
              <MenuItem
                active={handler.step.four}
                itemName={t("about.section.one.sidebar.four")}
                separate="four"
                blockName="block-four"
                top={-50}
                handler={handler}
              />
              <MenuItem
                active={handler.step.five}
                itemName={t("about.section.one.sidebar.five")}
                separate="five"
                blockName="block-five"
                top={100}
                handler={handler}
              />
            </div>
            <div className="content-desc">
              <div className="logo-image">
                <img src={AboutImageMediumIcon} alt="" />
              </div>
              <div className="content-desc-mission" id="block-one">
                <div className="content-desc-title">
                  {t("about.section.one.block.one.title")}
                </div>
                <div className="content-desc-description">
                  {t("about.section.one.block.one.desc")}
                </div>
              </div>
              <div className="content-desc-vision">
                <div className="image">
                  <img src={AboutSectionOneImage01} alt="" />
                </div>
                <div className="mix">
                  <div className="mix-image">
                    <img src={AboutSectionOneImage02} alt="" />
                  </div>
                  <div className="mix-content" id="block-two">
                    <div className="mix-content-title">
                      {t("about.section.one.block.two.title")}
                    </div>
                    <div className="mix-content-desc">
                      {t("about.section.one.block.two.desc")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="business" id="block-three">
                <div className="business-title">
                  {t("about.section.one.block.three.title")}
                </div>
                <div className="business-desc">
                  <Trans i18nKey={"about.section.one.block.three.desc"}></Trans>
                </div>
              </div>
            </div>
          </div>
          <div className="end-section-image">
            <img src={IconSanAn} alt="" />
          </div>
        </div>
        <SectionTwo />
        <SectionThree />
      </div>
      <FooterComponent />
    </>
  );
};

export default MainUI;
