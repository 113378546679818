import { Carousel } from "antd";
import { useTranslation } from "react-i18next";

const BASE_PATH = "/img";
const IMG_NAME_LIST = [
  "brand-01.png",
  "brand-02.png",
  "brand-03.png",
  // "brand-04.png",
  "brand-05.png",
  "brand-06.png",
  "brand-03.png",
];

const SectionCompany = () => {
  const { t } = useTranslation();

  return (
    <div className="section-company">
      <div className="title">
        <h3>{t("home.section.company.title")}</h3>
      </div>
      <div className="brand">
        <Carousel
          style={{ width: 1500, height: "100%" }}
          autoplay
          dots={false}
          speed={10000}
        >
          {[1, 2, 3].map((_, index) => (
            <div className="item" key={index}>
              {IMG_NAME_LIST.map((innerImgName, innerIndex) => (
                <div
                  key={innerIndex}
                  className="caurosel-img"
                  style={{
                    backgroundImage: `url(${BASE_PATH}/${innerImgName})`,
                  }}
                ></div>
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default SectionCompany;
