// Components
import PrivacyPage from "../../Pages/Privacy";
import { RouterType } from "../RouterType";

export const PrivacyRouter: RouterType[] = [
    {
        path: "/privacy",
        element: <PrivacyPage />,
    },
];
