import React from "react";
import FooterComponent from "../../../../Components/Footer/Footer";

// CSS
import "../../../../Assets/css/home.css";

// Components
import SectionTwo from "./SectionTwo";
import SectionOne from "./SectionOne";
import SectionFive from "./SectionFive";
import SectionHeader from "./SectionHeader";
import { HomeHandler } from "../Handler/HandlerImpl";
import LanguageComponent from "../../../../Components/Language/Language";
import SectionCompany from "./SectionCompany";
import { useDispatch } from "react-redux";
import { setOpenMenu } from "../../../../Components/Header/Slice/Slice";

type props = {
    handler: HomeHandler;
};

const MainUI = ({ handler }: props) => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        return () => {
            dispatch(setOpenMenu(false))
        }
    }, []);

    return (
        <>
            <SectionHeader handler={handler} />
            <SectionCompany />
            <SectionOne handler={handler} />
            <SectionTwo />
            <LanguageComponent /> {/*Section Language*/}
            <SectionFive />
            <FooterComponent />
        </>
    );
};

export default MainUI;
