import { useTranslation } from "react-i18next";

const SectionThree = () => {
  const { t } = useTranslation();

  return (
    <div
      className="policy-content-element"
      style={{ marginTop: 100 }}
      id="block-three"
    >
      <div className="policy-tt">{t("policy.section.three.title")}</div>
      <div className="policy-nd-element c3-nd-element">
        <div className="item c3-item">
          <div className="c3-text">
            {t("policy.section.threee.description")}
          </div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">01</div>
          <div className="c3-right">{t("policy.section.three.item.one")}</div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">02</div>
          <div className="c3-right">{t("policy.section.three.item.two")}</div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">03</div>
          <div className="c3-right">{t("policy.section.three.item.three")}</div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">04</div>
          <div className="c3-right">{t("policy.section.three.item.four")}</div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">05</div>
          <div className="c3-right">{t("policy.section.three.item.five")}</div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">06</div>
          <div className="c3-right">{t("policy.section.three.item.six")}</div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">07</div>
          <div className="c3-right">{t("policy.section.three.item.seven")}</div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">08</div>
          <div className="c3-right">{t("policy.section.three.item.eight")}</div>
        </div>
        <div className="item flex-start-item c3-item">
          <div className="c3-left">09</div>
          <div className="c3-right">{t("policy.section.three.item.nine")}</div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
