import React from "react";
import MainUI from "./Presenter/View/UI";
import EstimateHandler from "./Presenter/Handler/Handler";
import EstimateApi from "./Infrastructure/Api";
import EstimateService from "./Usecase/Service";

const EstimatePage = () => {
    const api = EstimateApi();
    const service = EstimateService(api);
    const handler = EstimateHandler(service);

    return <MainUI handler={handler} />;
};

export default EstimatePage;
