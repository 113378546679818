// CSS

import { Trans, useTranslation } from "react-i18next";
import "../../../../Assets/css/about/three.css";

import SectionThreeImage01 from "../../../../Assets/img/about-section-three-01.png";
import SectionThreeSign from "../../../../Assets/img/s3-sign.png";

const SectionThree = () => {
  const { t } = useTranslation();
  return (
    <div className="about-section-three" id="block-five">
      <div className="s3-container">
        <div className="s3-content-image">
          <div className="s3-img">
            <img src={SectionThreeImage01} alt="" />
          </div>

          <div className="s3-nd">
            <div className="s3-title">{t("about.section.three.title")}</div>
            <div className="s3-content">
              <Trans i18nKey="about.section.three.content"></Trans>
            </div>
          </div>
        </div>
        <div className="s3-content-desc"></div>
        <div className="s3-sign">
          <div className="s3-sign-draw">
            <img src={SectionThreeSign} alt="" />
          </div>
          <div>{t("about.section.three.sign.text")}</div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
