import React from "react";
import HeaderComponent from "../../../../Components/Header/Header";

// CSS
import "../../../../Assets/css/software/header.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store";

const SectioHeader = () => {
  const isOpen = useSelector((state: RootState) => state.header.isOpen);

  return (
    <div className="software-header" style={{ zIndex: isOpen ? 100 : 1 }}>
      <div className="software-header__container">
        <HeaderComponent />
      </div>
    </div>
  );
};

export default SectioHeader;
