import { useState } from "react";
import { StepEntity } from "../../Entity/Entity";

const DEFAULT_VALUE: StepEntity = {
  one: false,
  two: false,
  three: false,
  four: false,
  five: false,
};

const AboutHandler = () => {
  const [step, setStep] = useState<StepEntity>({
    ...DEFAULT_VALUE,
    one: true,
  });

  const changeStep = (key: string) => {
    switch (key) {
      case "one":
        setStep((prev: StepEntity) => {
          return { ...DEFAULT_VALUE, one: true };
        });
        break;
      case "two":
        setStep((prev: StepEntity) => {
          return { ...DEFAULT_VALUE, two: true };
        });
        break;
      case "three":
        setStep((prev: StepEntity) => {
          return { ...DEFAULT_VALUE, three: true };
        });
        break;
      case "four":
        setStep((prev: StepEntity) => {
          return { ...DEFAULT_VALUE, four: true };
        });
        break;
      case "five":
        setStep((prev: StepEntity) => {
          return { ...DEFAULT_VALUE, five: true };
        });
        break;
    }
  };

  return {
    step,

    changeStep,
  };
};

export default AboutHandler;
