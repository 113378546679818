import React from "react";

// CSS
import "../../../../Assets/css/home-section-five.css";

import ArrowRightSmall from "../../../../Assets/img/arrow-right-small-01.png";
import SectionFiveImage from "../../../../Assets/img/section-five-image.png";
import SectionFiveImage02 from "../../../../Assets/img/section-five-image-02.png";
import {
  SANANKIDS_SITE_URL,
  SANANNAVI_SITE_URL,
} from "../../../../Common/Constant";
import { useTranslation } from "react-i18next";

const SectionFive = () => {
  const { t } = useTranslation();

  return (
    <div className="home-section-five">
      <div className="title">
        <div className="section-heading">{t("home.section.five.heading")}</div>
        <div className="description">{t("home.section.five.description")}</div>
      </div>
      <div className="project">
        <div className="row">
          <div className="item">
            <div className="item-group">
              <div className="p-title">
                <a href={SANANKIDS_SITE_URL} target="_blank" rel="noreferrer">
                  SanAn Kids
                </a>
              </div>
              <div className="p-description">
                {t("home.section.five.sanan-kid.description")}
              </div>
              {/* <div className="p-read-more">
                                Read more{" "}
                                <img
                                    style={{ marginLeft: 14 }}
                                    src={ArrowRightSmall}
                                    alt=""
                                />
                            </div> */}
            </div>

            <div className="p-image">
              <img src={SectionFiveImage} alt="" />
            </div>
          </div>
          <div className="item">
            <div>
              <div className="p-title">
                <a href={SANANNAVI_SITE_URL} target="_blank" rel="noreferrer">
                  SanAn Navi
                </a>
              </div>
              <div className="p-description">
                {t("home.section.five.sanan-navi.description")}
              </div>
              {/* <div className="p-read-more">
                                Read more{" "}
                                <img
                                    style={{ marginLeft: 14 }}
                                    src={ArrowRightSmall}
                                    alt=""
                                />
                            </div> */}
            </div>

            <div className="p-image">
              <img src={SectionFiveImage02} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
