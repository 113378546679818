import React from "react";

import SectionTwoIcon from "../../../../Assets/img/section-two-icon-01.png";
import SectionTwoArrowRight from "../../../../Assets/img/section-two-arrow-right.png";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const SectionTwo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="home-section-two">
      <div className="title">
        <div className="big-title">
          <Trans i18nKey={"home.section.two.title"}></Trans>
        </div>
      </div>
      <div className="navigate">
        <div className="item item01" onClick={() => navigate("/software")}>
          <div className="title">
            <div className="unit-image-wrapper">
              <div className="unit-image">
                <img src={SectionTwoIcon} alt="" />
              </div>
              {t("home.section.two.item.one.title")}
            </div>
            <div className="unit-image arrow-left-sc2">
              <img src={SectionTwoArrowRight} alt="" />
            </div>
          </div>
        </div>
        <div className="item item02" onClick={() => navigate("/talent")}>
          <div className="title">
            <div className="unit-image-wrapper">
              <div className="unit-image">
                <img src={SectionTwoIcon} alt="" />
              </div>
              {t("home.section.two.item.two.title")}
            </div>
            <div className="unit-image">
              <img src={SectionTwoArrowRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
