import { useState } from "react";

const TalentHandler = () => {
    const [collapseOne, setCollapseOne] = useState<boolean>(true);
    const [collapseTwo, setCollapseTwo] = useState<boolean>(false);
    const [collapseThree, setCollapseThree] = useState<boolean>(false);

    const setupCollapseOne = () => {
        setCollapseOne((prev) => !prev);
    };

    const setupCollapseTwo = () => {
        setCollapseTwo((prev) => !prev);
    };

    const setupCollapseThree = () => {
        setCollapseThree((prev) => !prev);
    };

    return {
        collapseOne,
        collapseTwo,
        collapseThree,

        setupCollapseOne,
        setupCollapseTwo,
        setupCollapseThree
    };
};

export default TalentHandler;
