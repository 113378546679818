import React from "react";
import HeaderComponent from "../../../../Components/Header/Header";

// CSS
import "../../../../Assets/css/privacy/privacy.css";
import FooterComponent from "../../../../Components/Footer/Footer";

import { PrivacyHandler } from "../Handler/HandlerImpl";

import { useDispatch } from "react-redux";
import { setOpenMenu } from "../../../../Components/Header/Slice/Slice";
import { useTranslation } from "react-i18next";

type props = {
  handler: PrivacyHandler;
};

const MainUI = ({ handler }: props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {
      dispatch(setOpenMenu(false));
    };
  }, []);

  const { t } = useTranslation();

  return (
    <div className="privacy-container">
      <div className="privacy-header">
        <HeaderComponent />
      </div>
      <div className="privacy-title">
        <p>{t("privacy.title")}</p>
      </div>
      <div className="privacy-content">
        <p className="privacy__item">{t("privacy.description")}</p>
        <div className="privacy__item">
          <div className="privacy__item-title">
            {t("privacy.item.one.title")}
          </div>
          <div className="privacy__item-content">
            {t("privacy.item.one.content")}
          </div>
        </div>
        <div className="privacy__item">
          <div className="privacy__item-title">
            {t("privacy.item.two.title")}
          </div>
          <div className="privacy__item-content">
            {t("privacy.item.two.content")}
          </div>
        </div>
        <div className="privacy__item">
          <div className="privacy__item-title">
            {t("privacy.item.three.title")}
          </div>
          <div className="privacy__item-content">
            {t("privacy.item.three.content")}{" "}
          </div>
        </div>
        <div className="privacy__item">
          <div className="privacy__item-title">
            {t("privacy.item.four.title")}
          </div>
          <div className="privacy__item-content">
            {t("privacy.item.four.content")}
          </div>
        </div>
        <div className="privacy__item">
          <div className="privacy__item-title">
            {t("privacy.item.five.title")}
          </div>
          <div className="privacy__item-content">
            {t("privacy.item.five.content")}
          </div>
        </div>
        <div className="privacy__item">
          <div className="privacy__item-title">
            {t("privacy.item.six.title")}
          </div>
          <div className="privacy__item-content">
            {t("privacy.item.six.content")}{" "}
          </div>
        </div>
        <div className="privacy__signature">
          <p>{t("privacy.signature.line.one")}</p>
          <p>{t("privacy.signature.line.two")}</p>
          <p>{t("privacy.signature.line.three")}</p>
          <p>{t("privacy.signature.line.four")}</p>
        </div>
        <div className="privacy__contact">
          <p>{t("privacy.contact.line.one")}</p>
          <p>{t("privacy.contact.line.two")}</p>
          <br />
          <p>{t("privacy.contact.line.three")}</p>
          <p>{t('privacy.contact.line.four')}</p>
          <p>{t('privacy.contact.line.five')}</p>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default MainUI;
