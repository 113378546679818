import React from "react";
import HeaderComponent from "../../../../Components/Header/Header";

// CSS
import "../../../../Assets/css/talent/header.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store";

const SectionHeader = () => {
  const isOpen = useSelector((state: RootState) => state.header.isOpen);

  return (
    <div className={"talent-header"} style={{ zIndex: isOpen ? 100 : 1 }}>
      <div className="talent-header__container">
        <HeaderComponent />
      </div>
    </div>
  );
};

export default SectionHeader;
