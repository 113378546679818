// CSS
import "../../Assets/css/language.css";

// Image Backend
import BackendNode from "../../Assets/img/backend-node.png";
import BackendGo from "../../Assets/img/backend-go.png";
import BackendPHP from "../../Assets/img/backend-php.png";

// Image Frontend
import FrontendReact from "../../Assets/img/frontend-react.png";
import FrontendAngular from "../../Assets/img/frontend-angular.png";
import FrontendVue from "../../Assets/img/frontend-vue.png";
import FrontendFluter from "../../Assets/img/frontend-fluter.png";
// Image Database

import DatabaseMongo from "../../Assets/img/database-mongo.png";
import DatabaseSQL from "../../Assets/img/database-sql-server.png";
import DatabaseMySql from "../../Assets/img/database-mysql.png";
import DatabasePostgres from "../../Assets/img/database-postgres.png";

// Image Cloud

import CloudAmazon from "../../Assets/img/cloud-amazon.png";
import CloudAzure from "../../Assets/img/cloud-azure.png";
import CloudGoogle from "../../Assets/img/cloud-google.png";
import { useTranslation } from "react-i18next";

const LanguageComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="language-container">
      <div className="language-title">
        <div className="head">{t("language.header.title")}</div>
        <div className="desc">{t("language.header.description")}</div>
      </div>
      <div className="language-tech">
        <div className="item">
          <div className="item-tt">Backend</div>
          <div className="item-image">
            <div className="unit">
              <img src={BackendNode} alt="" />
            </div>
            <div className="unit">
              <img src={BackendGo} alt="" />
            </div>
            <div className="unit">
              <img src={BackendPHP} alt="" />
            </div>
          </div>
        </div>
        <div className="item">
          <div className="item-tt">Frontend</div>
          <div className="item-image item-image-adjust">
            <div className="unit">
              <img src={FrontendReact} alt="" />
            </div>
            <div className="unit">
              <img src={FrontendAngular} alt="" />
            </div>
            <div className="unit">
              <img src={FrontendVue} alt="" />
            </div>
            <div className="unit">
              <img src={FrontendFluter} alt="" />
            </div>
          </div>
        </div>
        <div className="item">
          <div className="item-tt">Database</div>
          <div className="item-image item-image-adjust">
            <div className="unit">
              <img src={DatabaseMongo} alt="" />
            </div>
            <div className="unit">
              <img src={DatabaseSQL} alt="" />
            </div>
            <div className="unit">
              <img src={DatabaseMySql} alt="" />
            </div>
            <div className="unit">
              <img src={DatabasePostgres} alt="" />
            </div>
          </div>
        </div>
        <div className="item">
          <div className="item-tt">Cloud</div>
          <div className="item-image item-image-adjust">
            <div className="unit">
              <img src={CloudAmazon} alt="" />
            </div>
            <div className="unit unit-mobile">
              <img src={CloudAzure} alt="" />
            </div>
            <div className="unit unit-mobile-01">
              <img src={CloudGoogle} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageComponent;
