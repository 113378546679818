import { useMemo, useState } from "react";
import { IsMobile } from "../../../../Common/Helpers";
import { SliderStepEntity } from "../../Entity/Entity";

const DEFAULT_VALUE: SliderStepEntity = {
  one: false,
  two: false,
  three: false,
  four: false,
  five: false,
};

const SoftwareHandler = () => {
  const [slider, setSlider] = useState<SliderStepEntity>({
    ...DEFAULT_VALUE,
    one: true,
  });

  const isMobile = useMemo(() => IsMobile(), [])

  const changeStepSlider = (key: string) => {
    switch (key) {
      case "one":
        setSlider((prev: SliderStepEntity) => {
          return { ...DEFAULT_VALUE, one: true };
        });
        break;
      case "two":
        setSlider((prev: SliderStepEntity) => {
          return { ...DEFAULT_VALUE, two: true };
        });
        break;
      case "three":
        setSlider((prev: SliderStepEntity) => {
          return { ...DEFAULT_VALUE, three: true };
        });
        break;
      case "four":
        setSlider((prev: SliderStepEntity) => {
          return { ...DEFAULT_VALUE, four: true };
        });
        break;
      case "five":
        setSlider((prev: SliderStepEntity) => {
          return { ...DEFAULT_VALUE, five: true };
        });
        break;
      default:
        setSlider((prev: SliderStepEntity) => {
          return { ...DEFAULT_VALUE };
        });
    }
  };

  return {
    slider,
    isMobile,

    changeStepSlider,
  };
};

export default SoftwareHandler;
