// Components
import SoftwarePage from "../../Pages/Software";
import { RouterType } from "../RouterType";

export const SoftwareRouter: RouterType[] = [
    {
        path: "/software",
        element: <SoftwarePage />,
    },
];
