// Components
import Home from "../../Pages/Home";
import { RouterType } from "../RouterType";

export const HomeRouter: RouterType[] = [
    {
        path: "/",
        element: <Home />,
    },
];
