// CSS
import { useTranslation } from "react-i18next";
import "../../../../Assets/css/about/two.css";

const SectionTwo = () => {
  const { t } = useTranslation();
  return (
    <div className="about-section-two" id="block-four">
      <div className="container">
        <div className="title">{t("about.section.two.title")}</div>
        <div className="content">
          <div className="item">
            <div className="item-title">
              {t("about.section.two.company-name.title")}
            </div>
            <div className="item-desc">
              {t("about.section.two.company-name.content")}
            </div>
          </div>
          <div className="item">
            <div className="item-title">
              {t("about.section.two.japanese-company-name.title")}
            </div>
            <div className="item-desc">
              {t("about.section.two.japanese-company-name.content")}
            </div>
          </div>
          <div className="item">
            <div className="item-title">
              {t("about.section.two.chairman.title")}
            </div>
            <div className="item-desc">
              {t("about.section.two.chairman.name")}
            </div>
          </div>
          <div className="item">
            <div className="item-title">{t('about.section.two.ceo.title')}</div>
            <div className="item-desc">{t('about.section.two.ceo.name')}</div>
          </div>
          <div className="item">
            <div className="item-title">
              {t("about.section.two.tokyo-office.title")}
            </div>
            <div className="item-desc">
              {t("about.section.two.tokyo-office.desc")}
            </div>
          </div>
          <div className="item">
            <div className="item-title">
              {t("about.section.two.ha-noi-office.title")}
            </div>
            <div className="item-desc">
              {t("about.section.two.ha-noi-office.desc")}
            </div>
          </div>
          <div className="item">
            <div className="item-title">
              {t("about.section.two.thanh-hoa-office.title")}
            </div>
            <div className="item-desc">
              {t("about.section.two.thanh-hoa-office.desc")}
            </div>
          </div>
          <div className="item">
            <div className="item-title">{t("about.section.two.tel.title")}</div>
            <div className="item-desc">{t("about.section.two.tel.desc")}</div>
          </div>
          <div className="item">
            <div className="item-title">{t('about.section.two.email.title')}</div>
            <div className="item-desc">{t('about.section.two.email.desc')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
