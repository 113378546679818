// Components
import TalentPage from "../../Pages/Talent";
import { RouterType } from "../RouterType";

export const TalentRouter: RouterType[] = [
  {
    path: "/talent",
    element: <TalentPage />,
  },
];
