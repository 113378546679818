import React from "react";
import MainUI from "./Presenter/View/UI";
import PolicyHandler from "./Presenter/Handler/Handler";

const PolicyPage = () => {
    const handler = PolicyHandler();

    return <MainUI handler={handler} />;
};

export default PolicyPage;
