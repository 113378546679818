import React from "react";
import MainUI from "./Presenter/View/UI";
import SoftwareHandler from "./Presenter/Handler/Handler";

const SoftwarePage = () => {
  const handler = SoftwareHandler();

  return <MainUI handler={handler} />;
};

export default SoftwarePage;
