import { useState } from "react";
import { SliderEntity, StepEntity } from "../../Entity/Entity";

import ImageSectionOne01 from "../../../../Assets/img/section-one-image.png";
import ImageSectionOne02 from "../../../../Assets/img/section-one-image-02.png";
import ImageSectionOne03 from "../../../../Assets/img/section-one-image-03.png";
import ImageSectionOne04 from "../../../../Assets/img/section-one-image-04.png";
import ImageSectionOne05 from "../../../../Assets/img/section-one-image-05.png";

const DEFAULT_VALUE: StepEntity = {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
};

const DEFALUT_VALUE_SLIDER: SliderEntity = {
    one: false,
    two: false,
    three: false,
};

const HomeHandler = () => {
    const [step, setStep] = useState<StepEntity>({
        ...DEFAULT_VALUE,
        one: true,
    });
    const [slider, setSlider] = useState<SliderEntity>({
        ...DEFALUT_VALUE_SLIDER,
        one: true,
    });
    const [classHeader, setClassHeader] = useState<string>("home-header-bg-01");
    const [image, setImage] = useState<string>(ImageSectionOne01);
    const [indexStep, setIndexStep] = useState<number>(0)
    const [indexSlider, setIndexSlider] = useState<number>(0)

    const changeStep = (key: string) => {
        switch (key) {
            case "one":
                setStep((prev: StepEntity) => {
                    return { ...DEFAULT_VALUE, one: true };
                });
                setImage(ImageSectionOne01);
                setIndexStep(0)
                break;
            case "two":
                setStep((prev: StepEntity) => {
                    return { ...DEFAULT_VALUE, two: true };
                });
                setImage(ImageSectionOne02);
                setIndexStep(1)
                break;
            case "three":
                setStep((prev: StepEntity) => {
                    return { ...DEFAULT_VALUE, three: true };
                });
                setImage(ImageSectionOne03);
                setIndexStep(2)
                break;
            case "four":
                setStep((prev: StepEntity) => {
                    return { ...DEFAULT_VALUE, four: true };
                });
                setImage(ImageSectionOne04);
                setIndexStep(3)
                break;
            case "five":
                setStep((prev: StepEntity) => {
                    return { ...DEFAULT_VALUE, five: true };
                });
                setImage(ImageSectionOne05);
                setIndexStep(4)
                break;
        }
    };

    const changeStepSlider = (key: string) => {
        switch (key) {
            case "one":
                setSlider((prev: SliderEntity) => {
                    return { ...DEFALUT_VALUE_SLIDER, one: true };
                });
                setClassHeader("home-header-bg-01");
                setIndexSlider(0)
                break;
            case "two":
                setSlider((prev: SliderEntity) => {
                    return { ...DEFALUT_VALUE_SLIDER, two: true };
                });
                setClassHeader("home-header-bg-02");
                setIndexSlider(1)
                break;
            case "three":
                setSlider((prev: SliderEntity) => {
                    return { ...DEFALUT_VALUE_SLIDER, three: true };
                });
                setClassHeader("home-header-bg-03");
                setIndexSlider(2)
                break;
        }
    };

    return {
        step,
        slider,
        classHeader,
        image,
        indexStep,
        indexSlider,

        changeStep,
        changeStepSlider,
    };
};

export default HomeHandler;
