import React from "react";
import MainUI from "./Presenter/View/UI";
import TalentHandler from "./Presenter/Handler/Handler";

const TalentPage = () => {
    const handler = TalentHandler();

    return <MainUI handler={handler} />;
};

export default TalentPage;
