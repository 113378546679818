import React from "react";
import MainUI from "./Presenter/View/UI";
import PrivacyHandler from "./Presenter/Handler/Handler";

const PrivacyPage = () => {
    const handler = PrivacyHandler();

    return <MainUI handler={handler} />;
};

export default PrivacyPage;
