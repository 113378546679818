import React from "react";

// CSS
import "../../../../Assets/css/talent/two.css";

import ImageStageOne from "../../../../Assets/img/talent-step-image-01.png";
import ImageStageTwo from "../../../../Assets/img/talent-step-image-02.png";
import ImageStageThree from "../../../../Assets/img/talent-step-image-03.png";
import ImageStageFour from "../../../../Assets/img/talent-step-image-04.png";
import ImageStageFive from "../../../../Assets/img/talent-step-image-05.png";
import IconStructure from "../../../../Assets/icon/Structure.svg";

import { Steps } from "antd";
import { Trans, useTranslation } from "react-i18next";

const SectionTwo = () => {
  const [current, setCurrent] = React.useState(0);
  const [indexStep, setIndexStep] = React.useState<number>(-1);

  React.useEffect(() => {
    let index = indexStep;
    const intervalId = setInterval(() => {
      index++;

      switch (index) {
        case 0:
          setCurrent(0);
          break;
        case 1:
          setCurrent(1);
          break;
        case 2:
          setCurrent(2);
          break;
        case 3:
          setCurrent(3);
          break;
        case 4:
          setCurrent(4);
          break;
      }

      if (index === 4) index = -1;
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [indexStep]);

  const onChange = (value: number) => {
    setCurrent(value);
    setIndexStep(value);
  };

  let image = "";

  switch (current) {
    case 0:
      image = ImageStageOne;
      break;
    case 1:
      image = ImageStageTwo;
      break;
    case 2:
      image = ImageStageThree;
      break;
    case 3:
      image = ImageStageFour;
      break;
    case 4:
      image = ImageStageFive;
      break;
  }

  const { t } = useTranslation();

  return (
    <div className="talent-section-two">
      <div className="content">
        <div className="title">
          <img className="icon-title" src={IconStructure} alt="" />
          {t("talent.section.two.title")}
        </div>
        <div className="step-container">
          <div className="step">
            <Steps
              style={{ height: "100%" }}
              current={current}
              onChange={onChange}
              direction="vertical"
              items={[
                {
                  title: t("talent.section.two.step.one.title"),
                  description:
                    current === 0
                      ? t("talent.section.two.step.one.description")
                      : "",
                },
                {
                  title: t("talent.section.two.step.two.title"),
                  description:
                    current === 1
                      ? t("talent.section.two.step.two.description")
                      : "",
                },
                {
                  title: t("talent.section.two.step.three.title"),
                  description:
                    current === 2
                      ? t("talent.section.two.step.three.description")
                      : "",
                },
                {
                  title: t("talent.section.two.step.four.title"),
                  description:
                    current === 3
                      ? t("talent.section.two.step.four.description")
                      : "",
                },
                {
                  title: t("talent.section.two.step.five.title"),
                  description:
                    current === 4 ? (
                      <span className="step-description">
                        <Trans
                          i18nKey={"talent.section.two.step.five.description"}
                        >
                          <span className="step-description-line"></span>
                        </Trans>
                      </span>
                    ) : (
                      ""
                    ),
                },
              ]}
            />
          </div>
          <div className="image">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
