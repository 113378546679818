import React from "react";

import AboutLeftIconWhire from "../../../../Assets/img/about-arrow-left-white.png";
import { AboutHandler } from "../Handler/HandlerImpl";

type MenuProps = {
    itemName: string;
    active: boolean;
    separate: string;
    blockName: string;
    top: number;
    handler: AboutHandler;
};

const MenuItem = ({
    itemName,
    active,
    separate,
    blockName,
    top,
    handler,
}: MenuProps) => {
    const [anchorTarget, setAnchorTarget] = React.useState<HTMLElement>();

    React.useEffect(() => {
        setAnchorTarget(document.getElementById(blockName) ?? undefined);
    }, [blockName]);

    const handleClick = (event: any) => {
        event.preventDefault();
        if (anchorTarget) {
            const y =
                anchorTarget.getBoundingClientRect().top +
                window.pageYOffset +
                top;
            window.scrollTo({ top: y, behavior: "smooth" });
        }
    };

    return (
        <div
            className={`item ${active ? "item-active" : ""}`}
            onClick={(e: any) => {
                handleClick(e);
                handler.changeStep(separate);
            }}
        >
            <div className="text">{itemName}</div>
            {active && (
                <div className="icon">
                    <img src={AboutLeftIconWhire} alt="" />
                </div>
            )}
        </div>
    );
};

export default MenuItem;
