import { FormEntity } from "../Entity/Entity";
import { EstimateServiceImpl } from "./ServiceImpl";

const EstimateService = (api: EstimateServiceImpl) => {
  const submitEstimateContent = async (data: FormEntity): Promise<any> => {
    try {
      return await api.submitEstimateContent(data);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    submitEstimateContent,
  };
};

export default EstimateService;
