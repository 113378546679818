import axios from "axios";
import queryString from "query-string";
import { AXIOS_TIMEOUT_REQUEST } from "../Common/Constant";

const mailConfig = axios.create({
    baseURL: process.env.REACT_APP_MAIL_API_URL,
    timeout: AXIOS_TIMEOUT_REQUEST,
    paramsSerializer: (params) => queryString.stringify(params),    
    withCredentials: false
});

// config request
mailConfig.interceptors.request.use();

// config response
mailConfig.interceptors.response.use();

export default mailConfig;
