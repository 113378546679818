import React from "react";

// CSS
import "../../../../Assets/css/talent/one.css";

// Image
import SoftwareAnalysis from "../../../../Assets/img/talent-section-one-icon-01.png";
import SoftwarePrototype from "../../../../Assets/img/talent-section-one-icon-02.png";
import SoftwareDevelopment from "../../../../Assets/img/talent-section-one-icon-03.png";
import { Trans, useTranslation } from "react-i18next";

const SectionOne = () => {
  const { t } = useTranslation();

  return (
    <div className="talent-section-one">
      <div className="title">
        <div className="s-head">
          <div className="desc">Talent Resources Service</div>
          <div className="quote">{t("talent.section.one.quote")}</div>
        </div>
        <div className="s-image">
          {/* <img src={TalentImage01} style={{ width: "124%" }} alt="" /> */}
        </div>
        <div className="s-content">
          <div className="head">
            <div className="st">{t("talent.section.one.item-list.title")}</div>
          </div>
          <div className="s-block">
            <div className="s-item">
              <div className="s-item-image">
                <div className="unit-image">
                  <img src={SoftwareAnalysis} alt="" />
                </div>
                <div className="s-desc s-desc-element-01">
                  {t("talent.section.one.item.one.title")}
                </div>
              </div>
              <div className="s-desc s-desc-element-02">
                {t("talent.section.one.item.one.title")}
              </div>
              <div className="s-card-content">
                {t("talent.section.one.item.one.content")}
              </div>
            </div>
            <div className="s-item">
              <div className="s-item-image">
                <div className="unit-image">
                  <img src={SoftwarePrototype} alt="" />
                </div>
                <div className="s-desc s-desc-element-01">
                  {t("talent.section.one.item.two.title")}
                </div>
              </div>
              <div className="s-desc s-desc-element-02">
                {t("talent.section.one.item.two.title")}
              </div>
              <div className="s-card-content">
                {t("talent.section.one.item.two.content")}
              </div>
            </div>
            <div className="s-item">
              <div className="s-item-image">
                <div className="unit-image">
                  <img src={SoftwareDevelopment} alt="" />
                </div>
                <div className="s-desc s-desc-element-01">
                  {t("talent.section.one.item.three.title")}
                </div>
              </div>
              <div className="s-desc s-desc-element-02">
                {t("talent.section.one.item.three.title")}
              </div>
              <div className="s-card-content">
                <Trans i18nKey="talent.section.one.item.three.content">
                  <span className="s-card-content-line"></span>
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
