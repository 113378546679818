// Components
import PolicyPage from "../../Pages/Policy";
import { RouterType } from "../RouterType";

export const PolicyRouter: RouterType[] = [
    {
        path: "/policy",
        element: <PolicyPage />,
    },
];
