import React from "react";

import PolicyLine from "../../../../Assets/img/policy-line.png";
import { useTranslation } from "react-i18next";

const SectionTwo = () => {
  const { t } = useTranslation();

  return (
    <div
      className="policy-content-element"
      style={{ marginTop: 100 }}
      id="block-two"
    >
      <div className="policy-tt">{t("policy.section.two.title")}</div>
      <div className="policy-nd-element">
        <div className="item">
          <div
            style={{
              marginRight: 8,
              height: 23,
            }}
          ></div>
          <div className="text">
            <span style={{ fontWeight: 700 }}>
              {t("policy.section.two.item.one.title")}
            </span>
            <br></br>
            {t("policy.section.two.item.one.description")}
            <div className="policy-element-spacer"></div>
          </div>
        </div>
        <div className="item">
          <div
            style={{
              marginRight: 8,
              height: 23,
            }}
          ></div>
          <div className="text">
            <span style={{ fontWeight: 700 }}>
              {t("policy.section.two.item.two.title")}
            </span>
            <br></br>
            {t("policy.section.two.item.two.description")}
            <div className="policy-element-spacer"></div>
          </div>
        </div>
        <div className="item">
          <div
            style={{
              marginRight: 8,
              height: 23,
            }}
          ></div>
          <div className="text">
            <span style={{ fontWeight: 700 }}>
              {t("policy.section.two.item.three.title")}
            </span>
            <br></br>
            {t("policy.section.two.item.three.description")}
            <div className="policy-element-spacer"></div>
          </div>
        </div>
        <div className="item">
          <div
            style={{
              marginRight: 8,
              height: 23,
            }}
          ></div>
          <div className="text">
            <span style={{ fontWeight: 700 }}>
              {t("policy.section.two.item.four.title")}
            </span>
            <br></br>
            {t("policy.section.two.item.four.description")}
            <div className="policy-nd-element-spacer"></div>
            <div className="policy-nd-element">
              <div className="item">
                <div className="item-left">
                  {t("policy.section.two.item.four.line.one.left")}
                </div>
                <div className="item-right">
                  {t("policy.section.two.item.four.line.one.right")}
                </div>
              </div>
              <div className="item">
                <div className="item-left">
                  {t("policy.section.two.item.four.line.two.left")}
                </div>
                <div className="item-right">
                  {t("policy.section.two.item.four.line.two.right")}
                </div>
              </div>
              <div className="item">
                <div className="item-left">
                  {t("policy.section.two.item.four.line.three.left")}
                </div>
                <div className="item-right">
                  {t("policy.section.two.item.four.line.three.right")}
                </div>
              </div>
              <div className="item">
                <div className="item-left">
                  {t("policy.section.two.item.four.line.four.left")}
                </div>
                <div className="item-right">
                  {t("policy.section.two.item.four.line.four.right")}
                </div>
              </div>
            </div>
            <div className="policy-nd-element-spacer"></div>
          </div>
        </div>
        <div className="item">
          <div
            style={{
              marginRight: 8,
              height: 23,
            }}
          ></div>
          <div className="text">
            <span style={{ fontWeight: 700 }}>
              {t("policy.section.two.item.five.title")}{" "}
            </span>
            <br></br>
            {t("policy.section.two.item.five.description")}
            <div className="policy-element-spacer"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
