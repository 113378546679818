import React from "react";

// CSS
import "../../Assets/css/contact.css";

// Image
import contactLogo from "../../Assets/img/contact-logo.png";
import documentLogo from "../../Assets/img/document-logo.png";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="footer-contact">
      <div
        className="content-one"
        onClick={() => navigate("/estimate-contact")}
      >
        <div className="icon">
          <img src={contactLogo} alt="contact logo" />
        </div>
        <p className="title">{t("footer.contact.content.one.title")}</p>
        <p className="description">
          {t("footer.contact.content.one.description")}
        </p>
      </div>

      <div
        className="content-two"
        onClick={() => navigate("/document-download")}
      >
        <div className="icon">
          <img src={documentLogo} alt="document logo" />
        </div>
        <p className="title">{t("footer.contact.content.two.title")}</p>
        <p className="description">
          {t("footer.contact.content.two.description")}
        </p>
      </div>
    </div>
  );
};

export default ContactComponent;
