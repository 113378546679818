import { FormEntity } from "../Entity/Entity";
import { DocumentServiceImpl } from "./ServiceImpl";

const DocumentService = (api: DocumentServiceImpl) => {
  const submitDocument = async (data: FormEntity): Promise<any> => {
    try {
      return await api.submitDocument(data);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    submitDocument,
  };
};

export default DocumentService;
