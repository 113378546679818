import "./App.css";
import BaseRouter from "./Router/Router";
import {
  I18nextProvider,
  initReactI18next,
  useTranslation,
} from "react-i18next";
import i18next from "i18next";
import translation_ja from "./locales/ja/translation.json";
import translation_en from "./locales/en/translation.json";
import translation_vi from "./locales/vi/translation.json";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./Redux/Store";

i18next.use(initReactI18next).init({
  debug: true,
  interpolation: { escapeValue: false },
  lng: "ja",
  fallbackLng: {
    default: ["ja"],
  },
  saveMissing: true,
  resources: {
    en: {
      translation: translation_en,
    },
    ja: {
      translation: translation_ja,
    },
    vi: {
      translation: translation_vi,
    },
  },
  react: {
    bindI18n: "languageChanged",
    bindI18nStore: "",
    transEmptyNodeValue: "",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    useSuspense: true,
  },
});
function App() {
  const { i18n } = useTranslation();
  const language = useSelector((state: RootState) => state.language.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <>
      <I18nextProvider i18n={i18next}>
        <BaseRouter />
      </I18nextProvider>
    </>
  );
}

export default App;
