import React from "react";
import SectionHeader from "./SectionHeader";
import SectionOne from "./SectionOne";
import FooterComponent from "../../../../Components/Footer/Footer";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import { TalentHandler } from "../Handler/HandlerImpl";
import { useDispatch } from "react-redux";
import { setOpenMenu } from "../../../../Components/Header/Slice/Slice";

type props = {
    handler: TalentHandler;
};

const MainUI = ({ handler }: props) => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        return () => {
            dispatch(setOpenMenu(false))
        }
    }, []);

    return (
        <>
            <SectionHeader />
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour handler={handler} />
            <FooterComponent />
        </>
    );
};

export default MainUI;
