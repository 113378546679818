import React from "react";
import MainUI from "./Presenter/View/UI";
import DocumentHandler from "./Presenter/Handler/Handler";
import DocumentApi from "./Infrastructure/Api";
import DocumentService from "./Usecase/Service";

const DocumentPage = () => {
  const api = DocumentApi();
  const service = DocumentService(api);

  const handler = DocumentHandler(service);

  return <MainUI handler={handler} />;
};

export default DocumentPage;
