import { createSlice } from "@reduxjs/toolkit";
import { HeaderSliceType } from "./Type";

export const initialState: HeaderSliceType = {
  isOpen: false,
};

export const HeaderSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setOpenMenu: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setOpenMenu } = HeaderSlice.actions;

export default HeaderSlice.reducer;
