// Components
import DocumentPage from "../../Pages/Document";
import EstimatePage from "../../Pages/Estimate";
import { RouterType } from "../RouterType";

export const DocumentRouter: RouterType[] = [
  {
    path: "/document-download",
    element: <DocumentPage />,
  },
  {
    path: "/estimate-contact",
    element: <EstimatePage />,
  },
];
