import React, { useRef, useState } from "react";
import HeaderComponent from "../../../../Components/Header/Header";

// CSS
import "../../../../Assets/css/document.css";
import FooterComponent from "../../../../Components/Footer/Footer";
import { Button, Checkbox, Form, Input, Select, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { EstimateHandler } from "../Handler/HandlerImpl";
import { useDispatch, useSelector } from "react-redux";
import { setOpenMenu } from "../../../../Components/Header/Slice/Slice";
import { RootState } from "../../../../Redux/Store";
import { Trans, useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/es/checkbox";

type props = {
  handler: EstimateHandler;
};

const MainUI = ({ handler }: props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isOpen = useSelector((state: RootState) => state.header.isOpen);
  const options: any = [
    {
      value: "DX支援・ITコンサルティング",
      label: t("estimate.inqury.item.consult.title"),
    },
    {
      value: "新規事業の企画・立ち上げ支援",
      label: t("estimate.inqury.item.planning.title"),
    },
    {
      value: "システム開発・ウェブ開発",
      label: t("estimate.inqury.item.system-web.title"),
    },
    { value: "アプリ開発", label: t("estimate.inqury.item.app.title") },
    {
      value: "基幹システム開発",
      label: t("estimate.inqury.item.core-system.title"),
    },
    { value: "その他", label: t("estimate.inqury.item.other.title") },
  ];

  const firstInputRef = useRef(null);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Focus first input on screen
    if (firstInputRef.current) {
      const inputElement: HTMLInputElement = firstInputRef.current;
      inputElement.focus();
    }

    return () => {
      dispatch(setOpenMenu(false));
    };
  }, []);

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const onPrivacyChecked = (e: CheckboxChangeEvent) => {
    setIsSubmitButtonEnabled(e.target.checked);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className={`document-header ${isOpen ? "open-menu-header" : ""}`}>
        <HeaderComponent />
      </div>
      <div className="document-content">
        <div className="document-description-form">
          <div className="document-title">{t("estimate.title")}</div>
          <div className="document-description">
            {t("estimate.description")}
          </div>
        </div>
        <Form form={handler.form} onFinish={handler.handleSubmit}>
          <div
            className="document-form"
            style={{ paddingTop: 68 }}
          >
            <Spin spinning={handler.loading}>
              <div className="document-form-input">
                <div>
                  <Form.Item
                    name="companyName"
                    rules={[
                      {
                        required: true,
                        message: t("estimate.form.message.required"),
                      },
                    ]}
                  >
                    <Input
                      className="item"
                      placeholder={t("estimate.form.field.company-name.title")}
                      ref={firstInputRef}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: t("estimate.form.message.required"),
                      },
                    ]}
                  >
                    <Input
                      className="item"
                      placeholder={t("estimate.form.field.full-name.title")}
                    />
                  </Form.Item>
                </div>
                <div className="group-input">
                  <Form.Item
                    name="emailAddress"
                    rules={[
                      {
                        required: true,
                        message: t("estimate.form.message.required"),
                      },
                    ]}
                  >
                    <Input
                      className="element"
                      placeholder={t("estimate.form.field.mail-address.title")}
                    />
                  </Form.Item>

                  <Form.Item name="telephoneNumber">
                    <Input
                      className="element"
                      placeholder={t("estimate.form.field.telephone.title")}
                    />
                  </Form.Item>
                </div>
                <div className="group-input">
                  <Form.Item name={"option"}>
                    <Select
                      className="element no-border"
                      style={{
                        border: "none !important",
                      }}
                      placeholder={t("estimate.form.field.inquiry-type.title")}
                      options={options}
                    ></Select>
                  </Form.Item>

                  <Form.Item name={"contentOfInquiry"}>
                    <TextArea
                      className="element"
                      style={{ height: 220 }}
                      cols={30}
                      placeholder={t(
                        "estimate.form.field.inquiry-content.title"
                      )}
                    />
                  </Form.Item>
                </div>
                <div className="center">
                  <Checkbox onChange={onPrivacyChecked}>
                    <Trans
                      i18nKey={"estimate.form.policy"}
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      components={{ 1: <a href="/policy"></a> }}
                    ></Trans>
                  </Checkbox>
                </div>
                <div>
                  <Button
                    disabled={!isSubmitButtonEnabled}
                    className={`item button ${
                      isSubmitButtonEnabled ? "" : "disabled"
                    }`}
                    onClick={() => handler.form?.submit()}
                  >
                    {t("estimate.form.send.title")}
                  </Button>
                </div>
              </div>
            </Spin>
          </div>
        </Form>
      </div>
      <FooterComponent />
    </div>
  );
};

export default MainUI;
