import React from "react";
import MainUI from "./Presenter/View/UI";
import AboutHandler from "./Presenter/Handler/Handler";

const AboutPage = () => {

    const handler = AboutHandler()

  return <MainUI handler={handler}/>;
};

export default AboutPage;
