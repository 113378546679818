import mailConfig from "../../../Configs/MailConfig";
import { FormEntity } from "../Entity/Entity";

const DocumentApi = () => {
  const submitDocument = async (data: FormEntity): Promise<any> => {
    const url = `/sendmail-v1`;
    return await mailConfig.post(url, data);
  };

  return {
    submitDocument,
  };
};

export default DocumentApi;
